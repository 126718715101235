import React from "react";

export interface FlagsState {
  mobileSmsIntegration185513492: boolean;
  whitepaperCeoPaper185130637: boolean;
  fingerprintExistingUsers: boolean;
  snaptradeHardcodeTypes180: boolean;
  loginservicedatacollection188: boolean;
  referafriend186015210: boolean;
  smsVerificationOn182: boolean;
  rgfholdingfix191: boolean;
  harrisPollWhitepaper354: boolean;
  whitepaperDownload185008833: boolean;
  referafriend25GiftcardWeek: boolean;
  siteNotifications184605453: boolean;
  stagingDevelopmentAllowedUsers: string[];
  fingerprintScoreCheck: boolean;
  snaptradeHardcodeTypes: boolean;
  fraudfuzzymatch179: boolean;
  webDownForMaintenance: boolean;
  aiBrokerageConnectGateway: boolean;
  enableInvestInTheNavBar: boolean;
}

export const defaultFlags: FlagsState = {
  mobileSmsIntegration185513492: false,
  whitepaperCeoPaper185130637: false,
  fingerprintExistingUsers: false,
  snaptradeHardcodeTypes180: false,
  loginservicedatacollection188: false,
  referafriend186015210: false,
  smsVerificationOn182: false,
  rgfholdingfix191: false,
  harrisPollWhitepaper354: false,
  whitepaperDownload185008833: false,
  referafriend25GiftcardWeek: false,
  siteNotifications184605453: false,
  stagingDevelopmentAllowedUsers: [],
  fingerprintScoreCheck: false,
  snaptradeHardcodeTypes: false,
  fraudfuzzymatch179: false,
  webDownForMaintenance: false,
  aiBrokerageConnectGateway: false,
  enableInvestInTheNavBar: false,
};

export const FlagsContext = React.createContext<FlagsState>(defaultFlags);
