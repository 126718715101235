import * as Types from '../../types.gen';

import * as DateIso from "@tiicker/util/lib/date-iso"
import * as TimeDateIso from "@tiicker/util/lib/date-iso/time-date-iso"
import type { FileUpload } from "graphql-upload/processRequest.mjs"
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  IsoDate: DateIso.Type;
  TimeIsoDate: TimeDateIso.Type;
  Upload: FileUpload;
};

export enum AccountStatusType {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  BrokerAccountError = 'BROKER_ACCOUNT_ERROR',
  Question = 'QUESTION'
}

export enum ActivityType {
  View = 'VIEW',
  Claim = 'CLAIM',
  Interact = 'INTERACT',
  OwnStock = 'OWN_STOCK',
  AnswerQuestion = 'ANSWER_QUESTION',
  ExternalArticleView = 'EXTERNAL_ARTICLE_VIEW',
  BrandWebsiteView = 'BRAND_WEBSITE_VIEW',
  RecommendClick = 'RECOMMEND_CLICK',
  Redirect = 'REDIRECT'
}

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['String'];
  getNumberOfClaimedPerks: NumberOfClaimedPerkResults;
  getCouponCodeDetails: CouponCodeDetails;
  getUserUploads: Array<UserUpload>;
  getUserUpload?: Maybe<AdminUserUploadPayload>;
};


export type AdminGetNumberOfClaimedPerksArgs = {
  perkId: Scalars['String'];
};


export type AdminGetCouponCodeDetailsArgs = {
  perkId: Scalars['String'];
};


export type AdminGetUserUploadsArgs = {
  userId: Scalars['Int'];
};


export type AdminGetUserUploadArgs = {
  uploadId: Scalars['Int'];
};

export type AdminUserUploadPayload = {
  __typename?: 'AdminUserUploadPayload';
  id: Scalars['Int'];
  upload: UserUpload;
  signedViewUrl: Scalars['String'];
};

export type AiAdminSettings = {
  __typename?: 'AiAdminSettings';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  fraudScore: Scalars['Int'];
  creationDate: Scalars['TimeIsoDate'];
};

export type Alert = {
  __typename?: 'Alert';
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  alertType: Scalars['String'];
};

export type AllPerksSearchResult = {
  __typename?: 'AllPerksSearchResult';
  totalPages: Scalars['Int'];
  results: Array<Maybe<AllPerksSearchResultItem>>;
};

export type AllPerksSearchResultItem = {
  __typename?: 'AllPerksSearchResultItem';
  id: Scalars['String'];
  title: Scalars['String'];
  imageUrl: Scalars['String'];
  brandLogo: Scalars['String'];
  brandLogoDark?: Maybe<Scalars['String']>;
  tickerSymbol: Scalars['String'];
  availableToClaim: Scalars['Boolean'];
  perkType?: Maybe<Scalars['String']>;
};

export type AnswerQuestionResponse = QuestionResponse | AuthenticateSuccessResponse;

export type AssociatedReferral = {
  __typename?: 'AssociatedReferral';
  id: Scalars['Int'];
  referralCode: Scalars['String'];
  referredFromUserId: Scalars['Int'];
  referredUserId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  referredDate: Scalars['TimeIsoDate'];
  referralAcceptedDate?: Maybe<Scalars['TimeIsoDate']>;
  email: Scalars['String'];
  referrerFirstName: Scalars['String'];
  referrerLastName: Scalars['String'];
  rewardClaimedByReferredUserDate?: Maybe<Scalars['TimeIsoDate']>;
  rewardClaimedByReferredFromUserDate?: Maybe<Scalars['TimeIsoDate']>;
  couponCode?: Maybe<Scalars['String']>;
  redemptionUrl?: Maybe<Scalars['String']>;
};

export type AuthenticateErrorResponse = {
  __typename?: 'AuthenticateErrorResponse';
  type: AuthenticateResponseType;
  message: Scalars['String'];
};

export type AuthenticateResponse = AuthenticateSuccessResponse | QuestionResponse | AuthenticateErrorResponse;

export enum AuthenticateResponseType {
  Success = 'SUCCESS',
  Question = 'QUESTION',
  Error = 'ERROR'
}

export type AuthenticateSuccessResponse = {
  __typename?: 'AuthenticateSuccessResponse';
  type: AuthenticateResponseType;
};

export type AutoLoginToken = {
  __typename?: 'AutoLoginToken';
  token: Scalars['String'];
  storeUrl: Scalars['String'];
};

export type AxomoColors = {
  __typename?: 'AxomoColors';
  technicalName: Scalars['String'];
  id: Scalars['String'];
  variationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  png: Scalars['String'];
  hex: Scalars['String'];
};

export type AxomoProduct = {
  __typename?: 'AxomoProduct';
  id: Scalars['String'];
  name: Scalars['String'];
  png: Scalars['String'];
  fullPrice: Scalars['Float'];
  details: Scalars['String'];
  outOfStockDiscontinue: Scalars['Boolean'];
  activeSizes: Array<AxomoSizes>;
  activeColors: Array<AxomoColors>;
  variationDetails: Array<AxomoVariationDetails>;
};

export type AxomoSizes = {
  __typename?: 'AxomoSizes';
  technicalName: Scalars['String'];
  id: Scalars['String'];
};

export type AxomoVariationDetails = {
  __typename?: 'AxomoVariationDetails';
  color: Scalars['String'];
  size: Scalars['String'];
  stockAvailable?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  productId: Scalars['String'];
  sizeId: Scalars['String'];
  colorId: Scalars['String'];
};

export type BackgroundGroupInput = {
  name: Scalars['String'];
  type: NftGroupType;
  dropId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
};

export type BasicBrand = {
  __typename?: 'BasicBrand';
  id: Scalars['Int'];
  ticker: Scalars['String'];
};

export type BoundingBox = {
  __typename?: 'BoundingBox';
  Width?: Maybe<Scalars['Float']>;
  Height?: Maybe<Scalars['Float']>;
  Left?: Maybe<Scalars['Float']>;
  Top?: Maybe<Scalars['Float']>;
};

export type BrandRecommendation = {
  __typename?: 'BrandRecommendation';
  id: Scalars['String'];
  ticker: Scalars['String'];
  probability: Scalars['Float'];
};

export type BrandWithPerkAvailability = {
  __typename?: 'BrandWithPerkAvailability';
  id: Scalars['Int'];
  brandName: Scalars['String'];
  tickerSymbol: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  heroImageUrl?: Maybe<Scalars['String']>;
  perkAvailable: Scalars['Boolean'];
  perkSummary?: Maybe<Scalars['String']>;
};

export type BrandWithPerkAvailabilityResult = {
  __typename?: 'BrandWithPerkAvailabilityResult';
  totalPages: Scalars['Int'];
  results: Array<BrandWithPerkAvailability>;
};

export type CanUserPurchaseResponse = {
  __typename?: 'CanUserPurchaseResponse';
  canPurchaseNft: Scalars['Boolean'];
  canPurchaseSwag: Scalars['Boolean'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  title: Scalars['String'];
  createdAt?: Maybe<Scalars['TimeIsoDate']>;
  updatedAt?: Maybe<Scalars['TimeIsoDate']>;
};

export enum CategoryType {
  Industry = 'Industry',
  Lifestyle = 'Lifestyle'
}

export type ClaimPerkAttempt = {
  __typename?: 'ClaimPerkAttempt';
  id: Scalars['Int'];
  perkId: Scalars['String'];
  tickerSymbol: Scalars['String'];
  attemptDate: Scalars['TimeIsoDate'];
  status: ClaimPerkAttemptStatus;
};

export enum ClaimPerkAttemptStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type ClaimedCoupon = {
  __typename?: 'ClaimedCoupon';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  tangoUtid: Scalars['String'];
  referralId: Scalars['Int'];
};

export type ClaimedPerk = {
  __typename?: 'ClaimedPerk';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  user: User;
  perkId: Scalars['String'];
  tickerSymbol: Scalars['String'];
  claimedOn?: Maybe<Scalars['TimeIsoDate']>;
};

export type ClaimedPerkDetail = {
  __typename?: 'ClaimedPerkDetail';
  claimedPerkId: Scalars['Int'];
  perkId: Scalars['String'];
  perkTitle: Scalars['String'];
  tickerSymbol: Scalars['String'];
  brandName: Scalars['String'];
  claimedOn: Scalars['TimeIsoDate'];
  options: Array<ClaimedPerkOption>;
  couponCode?: Maybe<Scalars['String']>;
  couponLabel?: Maybe<Scalars['String']>;
  redemptionUrl?: Maybe<Scalars['String']>;
  redemptionInstructions?: Maybe<Scalars['String']>;
  storeUrl?: Maybe<Scalars['String']>;
  perkType?: Maybe<Scalars['String']>;
  certificateUrl?: Maybe<Scalars['String']>;
};

export type ClaimedPerkOption = {
  __typename?: 'ClaimedPerkOption';
  id: Scalars['Int'];
  claimedPerkId?: Maybe<Scalars['Int']>;
  option: Scalars['String'];
  selectedValue: Scalars['String'];
};

export type ClaimedPerkOptionArgs = {
  option: Scalars['String'];
  value: Scalars['String'];
};

export type ClaimedPerkOrTierOptionArgs = {
  option: Scalars['String'];
  value: Scalars['String'];
  perkId: Scalars['String'];
};

export type ClaimedPerkShare = {
  __typename?: 'ClaimedPerkShare';
  contentfulPerkId: Scalars['String'];
  certificateUrl: Scalars['String'];
};

export type CoalesedHolding = {
  __typename?: 'CoalesedHolding';
  id: Scalars['String'];
  holdingId: Scalars['Int'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  price: Scalars['Float'];
  totalValue: Scalars['Float'];
  quantity: Scalars['Float'];
  hasPerks: Scalars['Boolean'];
  certificateUrl?: Maybe<Scalars['String']>;
};

export type CompanyStore = {
  __typename?: 'CompanyStore';
  id: Scalars['Int'];
  tickerSymbol: Scalars['String'];
  storeSubdomain: Scalars['String'];
  storeUrl: Scalars['String'];
};

export type CompletedGame = {
  __typename?: 'CompletedGame';
  id: Scalars['Int'];
  game: Scalars['String'];
  userId: Scalars['Int'];
  points?: Maybe<Scalars['Int']>;
  timeCompleted?: Maybe<Scalars['TimeIsoDate']>;
  gameMetadata?: Maybe<Array<GameMetadata>>;
};

export type ContactForm = {
  __typename?: 'ContactForm';
  name: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
};

export type ContactYourShareholderForm = {
  __typename?: 'ContactYourShareholderForm';
  name: Scalars['String'];
  email: Scalars['String'];
  companyName: Scalars['String'];
  phone: Scalars['String'];
};

export type CouponCode = {
  __typename?: 'CouponCode';
  id?: Maybe<Scalars['Int']>;
  couponCode?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['IsoDate']>;
  perkId: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
  assignedDate?: Maybe<Scalars['TimeIsoDate']>;
  redemptionUrl?: Maybe<Scalars['String']>;
  redemptionInstructions?: Maybe<Scalars['String']>;
  couponLabel?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['Int']>;
  giftcardId?: Maybe<Scalars['Int']>;
};

export type CouponCodeDetails = {
  __typename?: 'CouponCodeDetails';
  totalCoupons: Scalars['Int'];
  claimedCoupons: Scalars['Int'];
};

export type CouponInput = {
  code: Scalars['String'];
  date?: Maybe<Scalars['IsoDate']>;
};

export type CreateGiftcardForm = {
  __typename?: 'CreateGiftcardForm';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};

export type CreateInstitutionLoginInputs = {
  publicToken: Scalars['String'];
  userId: Scalars['Int'];
};

export type CreateInstitutionLoginResponse = InstitutionLogin | GeneralError;

export type CreateInvoiceResponse = {
  __typename?: 'CreateInvoiceResponse';
  isSuccess: Scalars['Boolean'];
  externalInvoiceId?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<InvoiceStatus>;
};

export type DocumentMetadata = {
  __typename?: 'DocumentMetadata';
  Pages?: Maybe<Scalars['Int']>;
};

export type DocumentResponse = {
  __typename?: 'DocumentResponse';
  jobId: Scalars['String'];
  status: ProcessingStatus;
  timestamp: Scalars['String'];
  s3DocumentKey?: Maybe<Scalars['String']>;
  s3DocumentBucket?: Maybe<Scalars['String']>;
  s3ImageKey?: Maybe<Scalars['String']>;
  s3ImageBucket?: Maybe<Scalars['String']>;
  textractRawText?: Maybe<Scalars['String']>;
  texttractResponse?: Maybe<TextractResponse>;
  result?: Maybe<DocumentResult>;
};

export type DocumentResult = {
  __typename?: 'DocumentResult';
  stockInfo?: Maybe<StockInfo>;
  fraudScore?: Maybe<Scalars['Int']>;
  fraudScoreReasoning?: Maybe<Scalars['String']>;
  imageAnalysis?: Maybe<Scalars['String']>;
  jsonAnalysis?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum EligibilityStatus {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
  Unknown = 'UNKNOWN',
  Alreadyclaimed = 'ALREADYCLAIMED',
  Otherclaimed = 'OTHERCLAIMED',
  Potentialfraud = 'POTENTIALFRAUD'
}

export type Entity = {
  __typename?: 'Entity';
  id: Scalars['Int'];
  entityType: EntityType;
  name: Scalars['String'];
  entityId: Scalars['String'];
  entityCategories?: Maybe<Array<Maybe<EntityCategory>>>;
};

export type EntityCategory = {
  __typename?: 'EntityCategory';
  entityId: Scalars['Int'];
  categoryId: Scalars['Int'];
  category: Category;
};

export type EntityCategoryReturn = {
  __typename?: 'EntityCategoryReturn';
  entityId: Scalars['Int'];
  categoryId: Scalars['Int'];
};

export enum EntityType {
  Company = 'COMPANY',
  Article = 'ARTICLE',
  Perk = 'PERK',
  LifestyleCategory = 'LIFESTYLE_CATEGORY'
}

export type EquityTradeFaultError = {
  __typename?: 'EquityTradeFaultError';
  faultError: Scalars['String'];
};

export type EquityTradeInputs = {
  symbol: Scalars['String'];
  side: Scalars['String'];
  quantity: Scalars['Int'];
  type: Scalars['String'];
  duration: Scalars['String'];
  clientOrderId: Scalars['String'];
  previewId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stop?: Maybe<Scalars['Float']>;
};

export type EquityTradePreview = {
  __typename?: 'EquityTradePreview';
  symbol: Scalars['String'];
  quantity: Scalars['Int'];
  orderCost: Scalars['Float'];
  commission: Scalars['Float'];
  cost: Scalars['Float'];
  type: Scalars['String'];
  limitPrice?: Maybe<Scalars['Float']>;
  stopPrice?: Maybe<Scalars['Float']>;
  previewId?: Maybe<Scalars['String']>;
  side: Scalars['String'];
};

export type EquityTradePreviewError = {
  __typename?: 'EquityTradePreviewError';
  errorList: Array<Scalars['String']>;
};

export type EquityTradePreviewResponse = EquityTradePreview | EquityTradePreviewError | EquityTradeFaultError;

export type EquityTradeResponse = {
  __typename?: 'EquityTradeResponse';
  id: Scalars['Int'];
  status: Scalars['String'];
};

export enum ErrorType {
  DuplicateAccount = 'DuplicateAccount'
}

export type FingerprintValuesMobile = {
  __typename?: 'FingerprintValuesMobile';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  suspectScore: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  emulator: Scalars['Int'];
  deviceTamper: Scalars['Int'];
  clonedApp: Scalars['Int'];
  factoryReset: Scalars['Int'];
  frida: Scalars['Int'];
  jailBroken: Scalars['Int'];
  vpn: Scalars['Int'];
  creationDate: Scalars['TimeIsoDate'];
};

export type FingerprintValuesWeb = {
  __typename?: 'FingerprintValuesWeb';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  suspectScore: Scalars['Int'];
  botDetection: Scalars['Int'];
  incognito: Scalars['Int'];
  vpn: Scalars['Int'];
  browserTampering: Scalars['Int'];
  virtualMachine: Scalars['Int'];
  privacySetting: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  dataCenter: Scalars['Int'];
  creationDate: Scalars['TimeIsoDate'];
};

export type FinishOrderResponse = {
  __typename?: 'FinishOrderResponse';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

export type FuzzyMatchParameters = {
  __typename?: 'FuzzyMatchParameters';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  nameSimilarity: Scalars['Int'];
  maskSimilarity: Scalars['Int'];
  batchEndTime: Scalars['TimeIsoDate'];
  creationDate: Scalars['TimeIsoDate'];
};

export type GameMetadata = {
  __typename?: 'GameMetadata';
  pageSlug: Scalars['String'];
  timeStamp: Scalars['TimeIsoDate'];
};

export enum GameType {
  CompleteProfile = 'Complete_Profile',
  ConnectBrokerage = 'Connect_Brokerage',
  DownloadMobile = 'Download_Mobile',
  EmailOptIn = 'Email_Opt_In',
  RedeemPerk = 'Redeem_Perk',
  Trade = 'Trade',
  ViewBrandPages = 'View_Brand_Pages',
  ViewInsights = 'View_Insights',
  ViewPerkOfWeek = 'View_Perk_Of_Week'
}

export type GeneralError = {
  __typename?: 'GeneralError';
  message: Scalars['String'];
  errorType?: Maybe<ErrorType>;
};

export type Giftcard = {
  __typename?: 'Giftcard';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};

export type GiftcardInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};

export type GlobalNotification = {
  __typename?: 'GlobalNotification';
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  iconType: Scalars['String'];
  contentfulId?: Maybe<Scalars['String']>;
  startOn: Scalars['IsoDate'];
  endOn?: Maybe<Scalars['IsoDate']>;
  createdOn: Scalars['TimeIsoDate'];
};

export type GlobalNotificationAcknowledgement = {
  __typename?: 'GlobalNotificationAcknowledgement';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  globalNotificationId: Scalars['Int'];
  isRead: Scalars['Boolean'];
};

export type Holding = {
  __typename?: 'Holding';
  id: Scalars['String'];
  plaidSecurityId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tickerSymbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['TimeIsoDate']>;
  updatedAt?: Maybe<Scalars['TimeIsoDate']>;
  quote?: Maybe<IexQuote>;
  hasAvailablePerks: Scalars['Boolean'];
  certificateId?: Maybe<Scalars['String']>;
};

export type HoldingPaginationOptions = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<HoldingSort>;
  sortDirection?: Maybe<SortDirection>;
  searchText?: Maybe<Scalars['String']>;
};

export type HoldingPaginationResponse = {
  __typename?: 'HoldingPaginationResponse';
  id?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalResults: Scalars['Int'];
  totalValue: Scalars['Float'];
  results: Array<CoalesedHolding>;
};

export enum HoldingSort {
  Name = 'name',
  TickerSymbol = 'tickerSymbol',
  ValueSum = 'valueSum',
  QuantitySum = 'quantitySum',
  PricePerShare = 'pricePerShare'
}

export type Iex = {
  __typename?: 'Iex';
  id: Scalars['String'];
  company?: Maybe<IexCompany>;
  quote?: Maybe<IexQuote>;
  history?: Maybe<IexHistoryChoices>;
  news?: Maybe<Array<IexNews>>;
};


export type IexCompanyArgs = {
  ticker: Scalars['String'];
};


export type IexQuoteArgs = {
  ticker: Scalars['String'];
};


export type IexHistoryArgs = {
  ticker: Scalars['String'];
};


export type IexNewsArgs = {
  ticker: Scalars['String'];
  count: Scalars['Int'];
};

export type IexCompany = {
  __typename?: 'IexCompany';
  id: Scalars['String'];
  symbol: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  CEO?: Maybe<Scalars['String']>;
  employees?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  quote?: Maybe<IexQuote>;
};

export type IexCompanySearchResult = {
  __typename?: 'IexCompanySearchResult';
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  securityName: Scalars['String'];
  subBrands?: Maybe<Array<SubBrandSearchResult>>;
  hasPerks?: Maybe<Scalars['Boolean']>;
};

export type IexHistory = {
  __typename?: 'IexHistory';
  id: Scalars['String'];
  data: Array<IexPricingSegment>;
};

export type IexHistoryChoices = {
  __typename?: 'IexHistoryChoices';
  id: Scalars['String'];
  h1d?: Maybe<IexHistory>;
  h1w?: Maybe<IexHistory>;
  h1m?: Maybe<IexHistory>;
  h3m?: Maybe<IexHistory>;
  h1y?: Maybe<IexHistory>;
  h5y?: Maybe<IexHistory>;
};

export type IexNews = {
  __typename?: 'IexNews';
  id: Scalars['String'];
  datetime: Scalars['TimeIsoDate'];
  headline: Scalars['String'];
  source: Scalars['String'];
  url: Scalars['String'];
  summary: Scalars['String'];
  related?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lang: Scalars['String'];
  hasPaywall?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  tickers?: Maybe<Array<Scalars['String']>>;
};

export type IexPricingSegment = {
  __typename?: 'IexPricingSegment';
  isoDate?: Maybe<Scalars['IsoDate']>;
  volume?: Maybe<Scalars['Float']>;
  close?: Maybe<Scalars['Float']>;
  minute?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
};

export type IexQuote = {
  __typename?: 'IexQuote';
  id: Scalars['String'];
  symbol: Scalars['String'];
  open?: Maybe<Scalars['Float']>;
  close?: Maybe<Scalars['Float']>;
  latestPrice?: Maybe<Scalars['Float']>;
  latestDate: Scalars['IsoDate'];
  change?: Maybe<Scalars['Float']>;
  changePercent?: Maybe<Scalars['Float']>;
  marketCap?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type InputAddressInfo = {
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type InputOrderItem = {
  quantity: Scalars['Int'];
  title: Scalars['String'];
  productType: ProductType;
  nftId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  sizeId?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  svg?: Maybe<Scalars['String']>;
  positionProps?: Maybe<InputPositionProps>;
  finalizedPrice?: Maybe<Scalars['Float']>;
  backgroundId?: Maybe<Scalars['Int']>;
  premiumBackground?: Maybe<Scalars['Boolean']>;
};

export type InputPositionProps = {
  logoX: Scalars['Int'];
  logoY: Scalars['Int'];
  logoWidth: Scalars['Int'];
  logoHeight: Scalars['Int'];
  tiiFontSize: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
};

export type InsightSearch = {
  __typename?: 'InsightSearch';
  id: Scalars['String'];
  slug: Scalars['String'];
  category: Scalars['String'];
  publishedDate: Scalars['IsoDate'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  heroImage?: Maybe<Scalars['String']>;
  relatedTickerSymbols: Array<Scalars['String']>;
};

export type InsightSearchPageResult = {
  __typename?: 'InsightSearchPageResult';
  id?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalResults: Scalars['Int'];
  results: Array<InsightSearch>;
};

export type InsightSearchResult = {
  __typename?: 'InsightSearchResult';
  title: Scalars['String'];
  slug: Scalars['String'];
  contentfulId: Scalars['String'];
};

export enum Institution {
  Etrade = 'ETRADE',
  Tradier = 'TRADIER',
  Tdameritrade = 'TDAMERITRADE',
  Questrade = 'QUESTRADE'
}

export type InstitutionAccount = {
  __typename?: 'InstitutionAccount';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  mask?: Maybe<Scalars['String']>;
  allCash?: Maybe<Scalars['Float']>;
  buyingPower?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  institutionLoginId: Scalars['Int'];
  createdAt?: Maybe<Scalars['TimeIsoDate']>;
  updatedAt?: Maybe<Scalars['TimeIsoDate']>;
  holdings: Array<Holding>;
  individualHoldings: Array<Holding>;
  stockDetails: StockDetail;
  stockValue?: Maybe<Scalars['Float']>;
  otherInvestmentsValue?: Maybe<Scalars['Float']>;
  cashValue?: Maybe<Scalars['Float']>;
  plaid?: Maybe<PlaidAccount>;
};


export type InstitutionAccountStockDetailsArgs = {
  symbol: Scalars['String'];
};

export type InstitutionLogin = {
  __typename?: 'InstitutionLogin';
  id: Scalars['Int'];
  institutionName: Scalars['String'];
  userId: Scalars['Int'];
  user: User;
  createdAt?: Maybe<Scalars['TimeIsoDate']>;
  updatedAt?: Maybe<Scalars['TimeIsoDate']>;
  institutionAccounts: Array<InstitutionAccount>;
  brokerAccountStatus: AccountStatusType;
  plaidStatus?: Maybe<PlaidLoginStatus>;
  isLoadingPlaidRecords: Scalars['Boolean'];
  lastPlaidSyncTime?: Maybe<Scalars['Float']>;
  lastSynchronizedAt?: Maybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  potentialError?: Maybe<Scalars['Boolean']>;
  confirmedError: Scalars['Boolean'];
  plaidInstitutionId?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  New = 'new',
  Expired = 'expired',
  Paid = 'paid',
  Confirmed = 'confirmed',
  Complete = 'complete',
  Invalid = 'invalid',
  RequiresAction = 'requiresAction'
}

export type InvoiceStatusResponse = {
  __typename?: 'InvoiceStatusResponse';
  id: Scalars['String'];
  status?: Maybe<InvoiceStatus>;
};

export enum InvoiceType {
  Stripe = 'Stripe',
  Braintree = 'Braintree',
  Bitpay = 'Bitpay',
  Giveaway = 'Giveaway'
}


export type LeadGeneration = {
  __typename?: 'LeadGeneration';
  id: Scalars['Int'];
  leadId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  companyName: Scalars['String'];
  companyTicker: Scalars['String'];
  submittedAt: Scalars['TimeIsoDate'];
};

export type ManualUploads = {
  __typename?: 'ManualUploads';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  jobId: Scalars['String'];
  s3ImageBucket: Scalars['String'];
  s3DocumentBucket: Scalars['String'];
  s3ImageKey: Scalars['String'];
  s3DocumentKey: Scalars['String'];
  status: Scalars['String'];
  creationDate: Scalars['TimeIsoDate'];
  needsApproval: Scalars['Boolean'];
  approved: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  generatePlaidLinkToken?: Maybe<Scalars['String']>;
  agreeToConnectAccountsTerms: User;
  setAgreeToMailingUpdates: User;
  updateAllUserInformation: User;
  updatePendingReferral?: Maybe<Referral>;
  updateUserAccountInformation: User;
  updateUserDemographicInformation: User;
  addInterestToUser: User;
  removeInterestFromUser: User;
  createInstitutionLoginForUser?: Maybe<CreateInstitutionLoginResponse>;
  removeInstitutionLoginForUser: User;
  answerLifestyleQuestion?: Maybe<UserLifestyle>;
  skipLifestyleQuestion?: Maybe<UserLifestyle>;
  updateUserHoldings: User;
  updateHoldingsForLogin: InstitutionLogin;
  previewTrade: EquityTradePreviewResponse;
  executeTrade: EquityTradeResponse;
  claimPerk: ClaimedPerk;
  claimPerkOrPerkTier: Array<ClaimedPerk>;
  claimTangoCoupon?: Maybe<ClaimedCoupon>;
  setPlaidStatusToActive: InstitutionLogin;
  createUserBrandInterest: UserBrandInterest;
  submitContactForm: ContactForm;
  submitReferAFriendForm?: Maybe<ReferAFriendForm>;
  submitBrandEvent: Scalars['Boolean'];
  createGiftcardForm?: Maybe<CreateGiftcardForm>;
  updateGiftcardForm?: Maybe<UpdateGiftcardForm>;
  submitPartnerWithUsForm: PartnerWithUsForm;
  submitWhitepaperForm: SubmitWhitepaperForm;
  submitPhoneVerificationForm: SubmitPhoneVerificationForm;
  createCouponCode?: Maybe<CouponCode>;
  addCouponCodesToPerk?: Maybe<Array<Maybe<CouponCode>>>;
  completeGame?: Maybe<CompletedGame>;
  redeemPointsToStore?: Maybe<Scalars['Int']>;
  gamePageView: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  scrubUser: Scalars['Boolean'];
  indexSearch: Scalars['Boolean'];
  indexInsights: Scalars['Boolean'];
  indexPerks: Scalars['Boolean'];
  indexBrands: Scalars['Boolean'];
  submitPlaidAccountConnectionErrorForm: Scalars['Boolean'];
  setLoginError: Scalars['Boolean'];
  updateAllCompanyPrices: Scalars['Boolean'];
  createActivity: Scalars['Boolean'];
  createCategory: Category;
  updateCategory?: Maybe<Category>;
  deleteCategory: Scalars['Boolean'];
  deleteGlobalNotification: Scalars['Boolean'];
  linkCategoryToEntity?: Maybe<EntityCategoryReturn>;
  unlinkCategoryToEntity: Scalars['Boolean'];
  assignIexCategories: Scalars['Boolean'];
  loadLifestyleCategories: Scalars['Boolean'];
  createSweepstakesEntry: Scalars['Int'];
  markPromoCodeClaimed?: Maybe<UserPromoCode>;
  getOrCreateStockCertificate?: Maybe<Scalars['String']>;
  createInvoice: CreateInvoiceResponse;
  linkWallet?: Maybe<UserWallet>;
  removeWallet?: Maybe<Scalars['Boolean']>;
  createNftBucket: NftBucket;
  updateNftBucket?: Maybe<NftBucket>;
  createNftDrop: NftDrop;
  updateNftDrop: NftDrop;
  uploadTiisToBucket: Scalars['Boolean'];
  createOrUpdateNft?: Maybe<Nft>;
  updateNft?: Maybe<Nft>;
  createOrUpdateOrder?: Maybe<Order>;
  addUserWalletToOrder: Scalars['Boolean'];
  orderAddress: Scalars['Boolean'];
  finishOrder: FinishOrderResponse;
  finishOrderById: FinishOrderResponse;
  placeBid: Scalars['Boolean'];
  createReferralPromoCode?: Maybe<ReferralPromoCode>;
  updateReferralPromoCode?: Maybe<ReferralPromoCode>;
  submitReferralPromoCode?: Maybe<Scalars['Boolean']>;
  resendEmailVerification: Scalars['Boolean'];
  resetUserPassword: Scalars['Boolean'];
  zaiusEvent: Scalars['Boolean'];
  triggerLogin: Scalars['Boolean'];
  createOrUpdateEntity: Scalars['Boolean'];
  processTradingOAuthState: Scalars['String'];
  addHoldingToUser: Scalars['Boolean'];
  createOrUpdateUserUpload?: Maybe<UserUpload>;
  collegeFootballVote: Scalars['Boolean'];
  updateUserName: Scalars['Boolean'];
  createOrUpdateNftBackgroundGroup?: Maybe<NftBackgroundGroup>;
  createOrUpdateNftBackground?: Maybe<NftBackground>;
  addNftBackgroundToGroup: Scalars['Boolean'];
  removeNftBackgroundFromGroup: Scalars['Boolean'];
  updateUserMetadata: Scalars['Boolean'];
  updateUserFingerprint: Scalars['Boolean'];
  updateProtectedUserMetadata: Scalars['Boolean'];
  newsletterSubmission: Scalars['Boolean'];
  submitEmailForPerks: Scalars['Boolean'];
  fixLoginPlaidStatus: Scalars['Boolean'];
  claimPerkAttemptResponse: Scalars['Boolean'];
  submitLeadGenerationForm: LeadGeneration;
  submitEmailForm: Scalars['Boolean'];
  submitGlobalNotificationForm: GlobalNotification;
  createOrUpdateGlobalNotification: GlobalNotification;
  updateGlobalNotification?: Maybe<GlobalNotification>;
  acknowledgeGlobalNotification: GlobalNotificationAcknowledgement;
  unacknowledgeGlobalNotification: Scalars['Boolean'];
  acknowledgeUserAlert: Scalars['Boolean'];
  unacknowledgeUserAlert: Scalars['Boolean'];
  recordLeadGenerationLog?: Maybe<LeadGeneration>;
  getPassivOAuthRedirect: Scalars['String'];
  passivBrokerageLinked: Scalars['Boolean'];
  submitContactYourShareholderForm: ContactYourShareholderForm;
  submitReferralBrokerageLimitForm: SubmitReferralBrokerageLimitForm;
  submitReferralCampaignForm: SubmitReferralCampaignForm;
  updateUserAccountProxyInformation: User;
  submitFuzzyMatchParametersForm: SubmitFuzzyMatchParametersForm;
  updateUserAccountInstagramInformation: User;
  createNewUser: NewUser;
  triggerFingerprintCheck: Scalars['Boolean'];
  updateFingerprintValuesWeb: UpdateFingerprintValuesWeb;
  updateFingerprintValuesMobile: UpdateFingerprintValuesMobile;
  submitManualUpload: SubmitManualUpload;
  submitPdfForAnalysis: PdfUploadResponse;
  submitAiAdminSettingsForm: SubmitAiAdminSettingsForm;
  saveMobileAnalysisResult?: Maybe<Scalars['Boolean']>;
  updateManualInfoApproval: SubmitManualUpload;
  deleteManualUpload: Scalars['Boolean'];
  addToKnownGoodTable: Scalars['Boolean'];
};


export type MutationGeneratePlaidLinkTokenArgs = {
  institutionLoginId?: Maybe<Scalars['Int']>;
  androidPackage?: Maybe<Scalars['String']>;
};


export type MutationAgreeToConnectAccountsTermsArgs = {
  userId: Scalars['Int'];
};


export type MutationSetAgreeToMailingUpdatesArgs = {
  userId: Scalars['Int'];
  data: Scalars['Boolean'];
};


export type MutationUpdateAllUserInformationArgs = {
  id: Scalars['Int'];
  data: UpdateAllUserInformationInputs;
};


export type MutationUpdatePendingReferralArgs = {
  referralId: Scalars['Int'];
  makeAvailable: Scalars['Boolean'];
};


export type MutationUpdateUserAccountInformationArgs = {
  id: Scalars['Int'];
  data: UpdateUserAccountInputs;
};


export type MutationUpdateUserDemographicInformationArgs = {
  id: Scalars['Int'];
  data: UpdateUserDemographicInputs;
};


export type MutationAddInterestToUserArgs = {
  id: Scalars['Int'];
  interest: Scalars['String'];
};


export type MutationRemoveInterestFromUserArgs = {
  id: Scalars['Int'];
  interest: Scalars['String'];
};


export type MutationCreateInstitutionLoginForUserArgs = {
  institutionLogin: CreateInstitutionLoginInputs;
};


export type MutationRemoveInstitutionLoginForUserArgs = {
  id: Scalars['Int'];
};


export type MutationAnswerLifestyleQuestionArgs = {
  userId: Scalars['Int'];
  questionId: Scalars['String'];
  answer: Scalars['String'];
};


export type MutationSkipLifestyleQuestionArgs = {
  userId: Scalars['Int'];
  questionId: Scalars['String'];
};


export type MutationUpdateUserHoldingsArgs = {
  userId: Scalars['Int'];
  forceUpdate?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateHoldingsForLoginArgs = {
  institutionLoginId: Scalars['Int'];
  forceUpdate?: Maybe<Scalars['Boolean']>;
};


export type MutationPreviewTradeArgs = {
  institutionAccountId: Scalars['Int'];
  options: EquityTradeInputs;
};


export type MutationExecuteTradeArgs = {
  institutionAccountId: Scalars['Int'];
  options: EquityTradeInputs;
};


export type MutationClaimPerkArgs = {
  userId: Scalars['Int'];
  perkId: Scalars['String'];
  options?: Maybe<Array<ClaimedPerkOptionArgs>>;
  owningBrandTicker?: Maybe<Scalars['String']>;
};


export type MutationClaimPerkOrPerkTierArgs = {
  userId: Scalars['Int'];
  perkId: Scalars['String'];
  options?: Maybe<Array<ClaimedPerkOrTierOptionArgs>>;
  owningBrandTicker?: Maybe<Scalars['String']>;
};


export type MutationClaimTangoCouponArgs = {
  giftcard: GiftcardInput;
  referralId: Scalars['Int'];
};


export type MutationSetPlaidStatusToActiveArgs = {
  institutionLoginId: Scalars['Int'];
};


export type MutationCreateUserBrandInterestArgs = {
  userId: Scalars['Int'];
  tickerSymbol: Scalars['String'];
};


export type MutationSubmitContactFormArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
};


export type MutationSubmitReferAFriendFormArgs = {
  email: Scalars['String'];
};


export type MutationSubmitBrandEventArgs = {
  email?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type MutationCreateGiftcardFormArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};


export type MutationUpdateGiftcardFormArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};


export type MutationSubmitPartnerWithUsFormArgs = {
  name: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  howDidYouFindUs?: Maybe<Scalars['String']>;
  howDidYouFindUsOther?: Maybe<Scalars['String']>;
};


export type MutationSubmitWhitepaperFormArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  paperType: Scalars['String'];
};


export type MutationSubmitPhoneVerificationFormArgs = {
  phoneNumber: Scalars['String'];
  verificationNumber: Scalars['String'];
  verified?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['TimeIsoDate']>;
  verifiedDate?: Maybe<Scalars['TimeIsoDate']>;
};


export type MutationCreateCouponCodeArgs = {
  referralId?: Maybe<Scalars['Int']>;
  giftcardId?: Maybe<Scalars['Int']>;
  couponCode: Scalars['String'];
  perkId: Scalars['String'];
  expirationDate?: Maybe<Scalars['IsoDate']>;
  redemptionUrl?: Maybe<Scalars['String']>;
  redemptionInstructions?: Maybe<Scalars['String']>;
};


export type MutationAddCouponCodesToPerkArgs = {
  perkId: Scalars['String'];
  coupons: Array<CouponInput>;
};


export type MutationCompleteGameArgs = {
  userId: Scalars['Int'];
  game: Scalars['String'];
  points: Scalars['Int'];
};


export type MutationRedeemPointsToStoreArgs = {
  store: Scalars['String'];
  points: Scalars['Int'];
};


export type MutationGamePageViewArgs = {
  slug: Scalars['String'];
  game: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationScrubUserArgs = {
  userId: Scalars['Int'];
};


export type MutationSubmitPlaidAccountConnectionErrorFormArgs = {
  details: Scalars['String'];
};


export type MutationSetLoginErrorArgs = {
  error: Scalars['Boolean'];
  institutionLoginId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationCreateActivityArgs = {
  title: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
  entityType: EntityType;
  activityType: ActivityType;
  session: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  title: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['Int'];
};


export type MutationDeleteGlobalNotificationArgs = {
  id: Scalars['Int'];
};


export type MutationLinkCategoryToEntityArgs = {
  categoryId: Scalars['Int'];
  entityId: Scalars['Int'];
};


export type MutationUnlinkCategoryToEntityArgs = {
  categoryId: Scalars['Int'];
  entityId: Scalars['Int'];
};


export type MutationCreateSweepstakesEntryArgs = {
  userId: Scalars['Int'];
  sweepstakesName: Scalars['String'];
};


export type MutationMarkPromoCodeClaimedArgs = {
  promoCodeId: Scalars['Int'];
};


export type MutationGetOrCreateStockCertificateArgs = {
  userId: Scalars['Int'];
  ticker: Scalars['String'];
};


export type MutationCreateInvoiceArgs = {
  amount: Scalars['Float'];
  invoiceType: InvoiceType;
  sessionId: Scalars['String'];
  paymentNonce?: Maybe<Scalars['String']>;
};


export type MutationLinkWalletArgs = {
  userId: Scalars['Int'];
  address: Scalars['String'];
};


export type MutationRemoveWalletArgs = {
  userId: Scalars['Int'];
  address: Scalars['String'];
};


export type MutationCreateNftBucketArgs = {
  name: Scalars['String'];
  price: Scalars['Float'];
  drop: Scalars['Int'];
  bidding: Scalars['Boolean'];
  excludedTiis: Scalars['Boolean'];
  default: Scalars['Boolean'];
  premiumAddition?: Maybe<Scalars['Float']>;
  hideFromSearch?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateNftBucketArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  drop: Scalars['Int'];
  bidding: Scalars['Boolean'];
  excludedTiis: Scalars['Boolean'];
  default: Scalars['Boolean'];
  premiumAddition?: Maybe<Scalars['Float']>;
  hideFromSearch?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateNftDropArgs = {
  name: Scalars['String'];
  date: Scalars['IsoDate'];
};


export type MutationUpdateNftDropArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  date: Scalars['IsoDate'];
};


export type MutationUploadTiisToBucketArgs = {
  tiis: Array<Scalars['String']>;
  bucketId: Scalars['Int'];
};


export type MutationCreateOrUpdateNftArgs = {
  name: Scalars['String'];
  bucketId: Scalars['Int'];
  userEmailToPurchase: Scalars['String'];
  biddingPurchasePrice: Scalars['Float'];
};


export type MutationUpdateNftArgs = {
  nftId: Scalars['Int'];
  name: Scalars['String'];
  bucketId: Scalars['Int'];
  userEmailToPurchase?: Maybe<Scalars['String']>;
  biddingPurchasePrice?: Maybe<Scalars['Float']>;
};


export type MutationCreateOrUpdateOrderArgs = {
  sessionId: Scalars['String'];
  orderItems: Array<InputOrderItem>;
};


export type MutationAddUserWalletToOrderArgs = {
  sessionId: Scalars['String'];
  walletAddress: Scalars['String'];
};


export type MutationOrderAddressArgs = {
  sessionId: Scalars['String'];
  addressInfo: InputAddressInfo;
};


export type MutationFinishOrderArgs = {
  sessionId: Scalars['String'];
};


export type MutationFinishOrderByIdArgs = {
  orderId: Scalars['Int'];
};


export type MutationPlaceBidArgs = {
  bid: Scalars['Float'];
  nftId: Scalars['Int'];
};


export type MutationCreateReferralPromoCodeArgs = {
  data: ReferralPromoCodeData;
};


export type MutationUpdateReferralPromoCodeArgs = {
  id: Scalars['Int'];
  data: ReferralPromoCodeData;
};


export type MutationSubmitReferralPromoCodeArgs = {
  referralCode: Scalars['String'];
};


export type MutationResendEmailVerificationArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type MutationResetUserPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationZaiusEventArgs = {
  vuid?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
};


export type MutationTriggerLoginArgs = {
  email: Scalars['String'];
};


export type MutationCreateOrUpdateEntityArgs = {
  entityId: Scalars['String'];
  contentType: Scalars['String'];
};


export type MutationProcessTradingOAuthStateArgs = {
  secret: Scalars['String'];
  oAuthState: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};


export type MutationAddHoldingToUserArgs = {
  userId: Scalars['Int'];
  symbol: Scalars['String'];
  quantity: Scalars['Int'];
};


export type MutationCreateOrUpdateUserUploadArgs = {
  inputs: UserUploadCreateOrUpdate;
};


export type MutationCollegeFootballVoteArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  team?: Maybe<Scalars['String']>;
  tii?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserNameArgs = {
  userId: Scalars['Int'];
  userName: Scalars['String'];
};


export type MutationCreateOrUpdateNftBackgroundGroupArgs = {
  group: BackgroundGroupInput;
};


export type MutationCreateOrUpdateNftBackgroundArgs = {
  background: NftBackgroundInput;
};


export type MutationAddNftBackgroundToGroupArgs = {
  backgroundId: Scalars['Int'];
  groupId: Scalars['Int'];
};


export type MutationRemoveNftBackgroundFromGroupArgs = {
  backgroundId: Scalars['Int'];
  groupId: Scalars['Int'];
};


export type MutationUpdateUserMetadataArgs = {
  userId?: Maybe<Scalars['Int']>;
  metadata: UserMetadataInput;
};


export type MutationUpdateUserFingerprintArgs = {
  userId?: Maybe<Scalars['Int']>;
  visitorId: Scalars['String'];
  requestId: Scalars['String'];
  deviceType: Scalars['String'];
};


export type MutationUpdateProtectedUserMetadataArgs = {
  userId?: Maybe<Scalars['Int']>;
  metadata: ProtectedUserMetadataInput;
};


export type MutationNewsletterSubmissionArgs = {
  email: Scalars['String'];
};


export type MutationSubmitEmailForPerksArgs = {
  email: Scalars['String'];
  ticker: Scalars['String'];
};


export type MutationFixLoginPlaidStatusArgs = {
  institutionLoginId: Scalars['Int'];
};


export type MutationClaimPerkAttemptResponseArgs = {
  claimPerkAttemptId: Scalars['Int'];
  status: ClaimPerkAttemptStatus;
};


export type MutationSubmitLeadGenerationFormArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  companyName: Scalars['String'];
  companyTicker: Scalars['String'];
};


export type MutationSubmitEmailFormArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSubmitGlobalNotificationFormArgs = {
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  iconType: Scalars['String'];
  contentfulId?: Maybe<Scalars['String']>;
  startOn: Scalars['IsoDate'];
  endOn?: Maybe<Scalars['IsoDate']>;
};


export type MutationCreateOrUpdateGlobalNotificationArgs = {
  id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  iconType: Scalars['String'];
  contentfulId?: Maybe<Scalars['String']>;
  startOn?: Maybe<Scalars['IsoDate']>;
  endOn?: Maybe<Scalars['IsoDate']>;
};


export type MutationUpdateGlobalNotificationArgs = {
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  iconType: Scalars['String'];
  contentfulId?: Maybe<Scalars['String']>;
  startOn?: Maybe<Scalars['IsoDate']>;
  endOn?: Maybe<Scalars['IsoDate']>;
};


export type MutationAcknowledgeGlobalNotificationArgs = {
  userId: Scalars['Int'];
  globalNotificationId: Scalars['Int'];
  isRead: Scalars['Boolean'];
};


export type MutationUnacknowledgeGlobalNotificationArgs = {
  userId: Scalars['Int'];
  globalNotificationId: Scalars['Int'];
};


export type MutationAcknowledgeUserAlertArgs = {
  id: Scalars['Int'];
};


export type MutationUnacknowledgeUserAlertArgs = {
  id: Scalars['Int'];
};


export type MutationRecordLeadGenerationLogArgs = {
  leadId: Scalars['String'];
};


export type MutationGetPassivOAuthRedirectArgs = {
  userId: Scalars['Int'];
  brokerageSlug: Scalars['String'];
};


export type MutationPassivBrokerageLinkedArgs = {
  userId: Scalars['Int'];
};


export type MutationSubmitContactYourShareholderFormArgs = {
  name: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationSubmitReferralBrokerageLimitFormArgs = {
  brokerageRequirement: Scalars['Int'];
  notes: Scalars['String'];
};


export type MutationSubmitReferralCampaignFormArgs = {
  startDate: Scalars['TimeIsoDate'];
  endDate: Scalars['TimeIsoDate'];
};


export type MutationUpdateUserAccountProxyInformationArgs = {
  id: Scalars['Int'];
  signup: Scalars['Boolean'];
  brand: Scalars['String'];
};


export type MutationSubmitFuzzyMatchParametersFormArgs = {
  nameSimilarity: Scalars['Int'];
  maskSimilarity: Scalars['Int'];
  batchEndTime: Scalars['TimeIsoDate'];
};


export type MutationUpdateUserAccountInstagramInformationArgs = {
  id: Scalars['Int'];
  instagram: Scalars['String'];
};


export type MutationCreateNewUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  referredFrom?: Maybe<Scalars['String']>;
  promoReferral?: Maybe<Scalars['String']>;
  partnerReferral?: Maybe<Scalars['String']>;
};


export type MutationTriggerFingerprintCheckArgs = {
  requestId: Scalars['String'];
  visitorId: Scalars['String'];
  deviceType: Scalars['String'];
};


export type MutationUpdateFingerprintValuesWebArgs = {
  suspectScore: Scalars['Int'];
  botDetection: Scalars['Int'];
  incognito: Scalars['Int'];
  vpn: Scalars['Int'];
  browserTampering: Scalars['Int'];
  virtualMachine: Scalars['Int'];
  privacySetting: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  dataCenter: Scalars['Int'];
};


export type MutationUpdateFingerprintValuesMobileArgs = {
  suspectScore: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  emulator: Scalars['Int'];
  deviceTamper: Scalars['Int'];
  clonedApp: Scalars['Int'];
  factoryReset: Scalars['Int'];
  frida: Scalars['Int'];
  jailBroken: Scalars['Int'];
  vpn: Scalars['Int'];
};


export type MutationSubmitManualUploadArgs = {
  userId: Scalars['Int'];
  jobId: Scalars['String'];
  s3ImageBucket: Scalars['String'];
  s3DocumentBucket: Scalars['String'];
  s3ImageKey: Scalars['String'];
  s3DocumentKey: Scalars['String'];
  status: Scalars['String'];
  creationDate: Scalars['TimeIsoDate'];
  needsApproval?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};


export type MutationSubmitPdfForAnalysisArgs = {
  file: Scalars['Upload'];
  filename: Scalars['String'];
};


export type MutationSubmitAiAdminSettingsFormArgs = {
  fraudScore: Scalars['Int'];
};


export type MutationSaveMobileAnalysisResultArgs = {
  results: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
};


export type MutationUpdateManualInfoApprovalArgs = {
  jobId: Scalars['String'];
  needsApproval?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteManualUploadArgs = {
  jobId: Scalars['String'];
  brokerage: Scalars['String'];
};


export type MutationAddToKnownGoodTableArgs = {
  jobId: Scalars['String'];
};

export type NewUser = {
  __typename?: 'NewUser';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  id: Scalars['String'];
  url: Scalars['String'];
  author: Scalars['String'];
  created: Scalars['IsoDate'];
  title: Scalars['String'];
  teaser: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  image: Array<NewsImage>;
  stocks: Array<NewsStocks>;
};

export type NewsImage = {
  __typename?: 'NewsImage';
  size: Scalars['String'];
  url: Scalars['String'];
};

export type NewsStocks = {
  __typename?: 'NewsStocks';
  name: Scalars['String'];
};

export type Nft = {
  __typename?: 'Nft';
  id: Scalars['Int'];
  name: Scalars['String'];
  bucketId: Scalars['Int'];
  animationUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  mintedDate?: Maybe<Scalars['TimeIsoDate']>;
  userAllowedToPurchase?: Maybe<Scalars['Int']>;
  biddingPurchasePrice?: Maybe<Scalars['Float']>;
  dateAllowedToPurchase?: Maybe<Scalars['TimeIsoDate']>;
  isLocked: Scalars['Boolean'];
  hasPlacedBid: Scalars['Boolean'];
  etherscanUrl?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  backgroundId?: Maybe<Scalars['Int']>;
  patternId?: Maybe<Scalars['Int']>;
  bucket: NftBucket;
};

export enum NftActivityStatus {
  NotStarted = 'NotStarted',
  Initiated = 'Initiated',
  Unconfirmed = 'Unconfirmed',
  Verified = 'Verified',
  Error = 'Error'
}

export enum NftActivityType {
  ImageGeneration = 'ImageGeneration',
  BackgroundGeneration = 'BackgroundGeneration',
  AnimationGeneration = 'AnimationGeneration',
  Minting = 'Minting',
  Payment = 'Payment',
  AxomoOrder = 'AxomoOrder'
}

export type NftBackground = {
  __typename?: 'NftBackground';
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
  hex?: Maybe<Scalars['String']>;
};

export type NftBackgroundGroup = {
  __typename?: 'NftBackgroundGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: NftGroupType;
  percentage: Scalars['Float'];
  priceAddition: Scalars['Float'];
  dropId: Scalars['Int'];
  backgrounds: Array<NftBackground>;
};

export type NftBackgroundInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  hex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type NftBucket = {
  __typename?: 'NftBucket';
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  dropId?: Maybe<Scalars['Int']>;
  bidding: Scalars['Boolean'];
  excludedTiis: Scalars['Boolean'];
  default: Scalars['Boolean'];
  premiumAddition?: Maybe<Scalars['Float']>;
};

export type NftDrop = {
  __typename?: 'NftDrop';
  id: Scalars['Int'];
  name: Scalars['String'];
  date: Scalars['IsoDate'];
};

export enum NftGroupType {
  Color = 'Color',
  Pattern = 'Pattern',
  Premium = 'Premium'
}

export type NftMintStatusResponse = {
  __typename?: 'NftMintStatusResponse';
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  stepStatuses: Array<NftMintStepProgress>;
};

export type NftMintStepProgress = {
  __typename?: 'NftMintStepProgress';
  step: NftActivityType;
  status: NftActivityStatus;
};

export type NftQuery = {
  __typename?: 'NftQuery';
  id: Scalars['String'];
  getBucket?: Maybe<NftBucket>;
  getAllBuckets: Array<NftBucket>;
  getPaginatedNftForBucket: PaginatedNft;
  getNftByName?: Maybe<Nft>;
  getMintingStatus: NftMintStatusResponse;
  getInvoiceStatus: InvoiceStatusResponse;
  canUserPurchase: CanUserPurchaseResponse;
  getNftsInBucket: Array<Nft>;
  getNftBackground?: Maybe<NftBackground>;
  getNftBackgroundGroup?: Maybe<NftBackgroundGroup>;
  getAllNftBackgroundGroups: Array<NftBackgroundGroup>;
  getAllNftBackgrounds: Array<NftBackground>;
  getNftByImageId?: Maybe<Nft>;
  getUserBid: Scalars['Boolean'];
};


export type NftQueryGetBucketArgs = {
  bucketId: Scalars['Int'];
};


export type NftQueryGetPaginatedNftForBucketArgs = {
  bucketId: Scalars['Int'];
  page: Scalars['Int'];
};


export type NftQueryGetNftByNameArgs = {
  name: Scalars['String'];
};


export type NftQueryGetMintingStatusArgs = {
  name: Scalars['String'];
};


export type NftQueryGetInvoiceStatusArgs = {
  orderId: Scalars['Int'];
};


export type NftQueryCanUserPurchaseArgs = {
  nft: Scalars['String'];
};


export type NftQueryGetNftsInBucketArgs = {
  bucketId: Scalars['Int'];
  page: Scalars['Int'];
};


export type NftQueryGetNftBackgroundArgs = {
  backgroundId: Scalars['Int'];
};


export type NftQueryGetNftBackgroundGroupArgs = {
  backgroundGroupId: Scalars['Int'];
};


export type NftQueryGetAllNftBackgroundGroupsArgs = {
  dropId?: Maybe<Scalars['Int']>;
  nftId?: Maybe<Scalars['Int']>;
};


export type NftQueryGetAllNftBackgroundsArgs = {
  groupId?: Maybe<Scalars['Int']>;
  type?: Maybe<NftGroupType>;
  dropId?: Maybe<Scalars['Int']>;
};


export type NftQueryGetNftByImageIdArgs = {
  imageId: Scalars['String'];
};


export type NftQueryGetUserBidArgs = {
  userId: Scalars['Int'];
  nftId: Scalars['Int'];
};

export type NftSearchBucketResult = {
  __typename?: 'NftSearchBucketResult';
  bucketId: Scalars['Int'];
  name: Scalars['String'];
  default: Scalars['Boolean'];
  hideFromSearch?: Maybe<Scalars['Boolean']>;
  nfts: Array<Nft>;
};

export enum NotificationType {
  RecurringPerkAvailableAutoClaim = 'RecurringPerkAvailableAutoClaim',
  RecurringPerkAvailableManualClaim = 'RecurringPerkAvailableManualClaim',
  RecurringPerkNoLongerAvailable = 'RecurringPerkNoLongerAvailable',
  InvalidInstitutionLogin = 'InvalidInstitutionLogin',
  General = 'General'
}

export type NumberOfClaimedPerkResults = {
  __typename?: 'NumberOfClaimedPerkResults';
  total: Scalars['Int'];
  perkId: Scalars['String'];
};

export type OAuthUrlBundle = {
  __typename?: 'OAuthUrlBundle';
  url: Scalars['String'];
  state: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Int'];
  sessionId: Scalars['String'];
  status: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
  purchasedDate?: Maybe<Scalars['TimeIsoDate']>;
  invoiceId?: Maybe<Scalars['Int']>;
  orderItems: Array<OrderItem>;
  subTotal: Scalars['Float'];
  shippingCost?: Maybe<Scalars['Float']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  orderId: Scalars['Int'];
  title: Scalars['String'];
  quantity: Scalars['Int'];
  dateAdded: Scalars['TimeIsoDate'];
  productType: ProductType;
  nftId?: Maybe<Scalars['Int']>;
  png?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  sizeId?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['Int']>;
  trackingNumber?: Maybe<Scalars['String']>;
  shippedDate?: Maybe<Scalars['TimeIsoDate']>;
  color?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  svg?: Maybe<Scalars['String']>;
  positionProps?: Maybe<PositionProps>;
  nftName?: Maybe<Scalars['String']>;
  backgroundId?: Maybe<Scalars['Int']>;
  premiumBackground?: Maybe<Scalars['Boolean']>;
  patternId?: Maybe<Scalars['Int']>;
};

export type PdfUploadResponse = {
  __typename?: 'PDFUploadResponse';
  message: Scalars['String'];
  jobId: Scalars['String'];
};

export type PagedGlobalNotifications = {
  __typename?: 'PagedGlobalNotifications';
  id: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  data: Array<GlobalNotification>;
};

export type PagedReferralPromoCodes = {
  __typename?: 'PagedReferralPromoCodes';
  id: Scalars['String'];
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  data: Array<ReferralPromoCode>;
};

export type PaginatedNft = {
  __typename?: 'PaginatedNft';
  total: Scalars['Int'];
  nfts: Array<Nft>;
};

export type PartnerWithUsForm = {
  __typename?: 'PartnerWithUsForm';
  name: Scalars['String'];
  companyName: Scalars['String'];
  companyWebsite?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  favoritePerk?: Maybe<Scalars['String']>;
  howDidYouFindUs?: Maybe<Scalars['String']>;
  howDidYouFindUsOther?: Maybe<Scalars['String']>;
};

export type PassivBrokerage = {
  __typename?: 'PassivBrokerage';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  url: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  squareLogo?: Maybe<Scalars['String']>;
};

export type PaymentsQuery = {
  __typename?: 'PaymentsQuery';
  id: Scalars['String'];
  getClientToken: Scalars['String'];
};


export type PaymentsQueryGetClientTokenArgs = {
  sessionId: Scalars['String'];
  invoiceType?: Maybe<InvoiceType>;
  amount?: Maybe<Scalars['Float']>;
};

export type PerkSearchResult = {
  __typename?: 'PerkSearchResult';
  title: Scalars['String'];
  tickerSymbol: Scalars['String'];
  contentfulId: Scalars['String'];
  brandName: Scalars['String'];
  brandTicker: Scalars['String'];
  perkImageUrl: Scalars['String'];
  brandLogoUrl: Scalars['String'];
  perkType?: Maybe<Scalars['String']>;
};

export type PlaidAccount = {
  __typename?: 'PlaidAccount';
  id: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type PlaidHolding = {
  __typename?: 'PlaidHolding';
  id: Scalars['String'];
  security_id: Scalars['String'];
  account_id: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  institution_price?: Maybe<Scalars['Float']>;
  institution_value?: Maybe<Scalars['Float']>;
};

export enum PlaidLoginStatus {
  Connected = 'Connected',
  NeedsAuthentication = 'NeedsAuthentication',
  NeedsReconnect = 'NeedsReconnect',
  Verifying = 'Verifying',
  DuplicateAccount = 'DuplicateAccount',
  Expired = 'Expired'
}

export type Point = {
  __typename?: 'Point';
  X?: Maybe<Scalars['Float']>;
  Y?: Maybe<Scalars['Float']>;
};

export type PopularSearch = {
  __typename?: 'PopularSearch';
  name: Scalars['String'];
  tickerSymbol: Scalars['String'];
};

export type PositionProps = {
  __typename?: 'PositionProps';
  logoX: Scalars['Int'];
  logoY: Scalars['Int'];
  logoWidth: Scalars['Int'];
  logoHeight: Scalars['Int'];
  tiiFontSize: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
};

export enum ProcessingStatus {
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export enum ProductType {
  Axomo = 'AXOMO',
  Nft = 'NFT'
}

export type ProtectedUserMetadataInput = {
  disablePerks?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  loggedInUser?: Maybe<User>;
  iex: Iex;
  trading: Trading;
  admin: Admin;
  getPassivBrokerages: Array<PassivBrokerage>;
  getUsersReferrals: Array<Referral>;
  getUsersPendingReferrals: Array<Referral>;
  getUsersReferralRewards: Array<ReferralReward>;
  getReferralsAssociatedWithUser: Array<AssociatedReferral>;
  getAllGiftcards: Array<Giftcard>;
  getAllActiveGiftcards: Array<Giftcard>;
  getTangoCatalog: Array<TangoCatalog>;
  getInstitutionLoginsForUser: Array<InstitutionLogin>;
  getActiveInstitutionLoginsForUser: Array<InstitutionLogin>;
  getInstitutionLogin?: Maybe<InstitutionLogin>;
  getLifestyleQuestionsForUser: Array<UserLifestyle>;
  getUserPerks: Array<UserPerk>;
  getHasActivePerksForBrand: Scalars['Boolean'];
  getHasClaimedPerkForBrand: Scalars['Boolean'];
  getUserBrandInterest?: Maybe<UserBrandInterest>;
  search: SearchResult;
  getHasCompanyStore: Scalars['Boolean'];
  getStoreAutoLoginToken?: Maybe<AutoLoginToken>;
  getReferralCode: UserReferralCode;
  getHasEnoughPoints: Scalars['Boolean'];
  getCompletedGames?: Maybe<Array<CompletedGame>>;
  insightsSearch: InsightSearchPageResult;
  getUserCouponCodeForPerk?: Maybe<CouponCode>;
  getUserByEmail?: Maybe<User>;
  getUserByPhoneNumber?: Maybe<Scalars['String']>;
  getUser?: Maybe<User>;
  topTen?: Maybe<Array<RequestedPerkData>>;
  brandSearch?: Maybe<Array<IexCompanySearchResult>>;
  paginatedHoldings: HoldingPaginationResponse;
  getUserDetailedHoldingsForSymbols: Array<CoalesedHolding>;
  getUserHoldingCount: UserStockHoldingResponse;
  getUserHoldingsForSymbols: UserStockHoldingResponse;
  getCategories: Array<Category>;
  getEntitiesByCategory: Array<Entity>;
  getEntity?: Maybe<Entity>;
  getEntities: Array<Entity>;
  getEntitiesWithVoting: Array<Scalars['String']>;
  getSweepstakesEntryPoints: Scalars['Int'];
  getRecommendationsForUser: UserRecommendation;
  paymentsQuery: PaymentsQuery;
  getNftBucket?: Maybe<NftBucket>;
  getNftDrop?: Maybe<NftDrop>;
  getAllNftBuckets: Array<NftBucket>;
  getAllNftDrops: Array<NftDrop>;
  getUserById?: Maybe<User>;
  getPaginatedNftForBucket: PaginatedNft;
  searchNft: SearchNftResult;
  getPopularNftsInBuckets: Array<NftSearchBucketResult>;
  getUserPromos: Array<UserPromoCode>;
  recentlyClaimedNfts: Array<Nft>;
  nftQuery: NftQuery;
  getUserPerkCertificateUrl?: Maybe<Scalars['String']>;
  getAxomoProductInfo?: Maybe<AxomoProduct>;
  getAxomoProductImage?: Maybe<Scalars['String']>;
  getActiveCart?: Maybe<Order>;
  getUserWallets: Array<UserWallet>;
  getOrder?: Maybe<Order>;
  getReferralPromoCode?: Maybe<ReferralPromoCode>;
  getGlobalNotification?: Maybe<GlobalNotification>;
  getPaginatedPromoCodes: PagedReferralPromoCodes;
  getPaginatedGlobalNotifications: PagedGlobalNotifications;
  getActiveGlobalNotifications: Array<GlobalNotification>;
  getGlobalNotificationAcknowledgementsForUser: Array<GlobalNotificationAcknowledgement>;
  getAlertsForUser: Array<UserAlert>;
  getAlertsForUserWithOneConnectionIssue: Array<UserAlert>;
  getReferralPromoCodesForUser: Array<UserReferralPromoCode>;
  getUserNftNames: Array<Maybe<Nft>>;
  getShippingCost?: Maybe<Scalars['Float']>;
  getNewsById?: Maybe<IexNews>;
  getNewsForUser: Array<IexNews>;
  getMonthlyPopularBrandSearchTerms: Array<PopularSearch>;
  getBrandsFromSearch: BrandWithPerkAvailabilityResult;
  getInsightsFromSearch: SearchInsights;
  getCryptoNews: Array<IexNews>;
  getWIIM24Hours: Array<IexNews>;
  getMonthsPopularPerks: Array<Scalars['String']>;
  getRecentlyClaimedPerks: Array<Scalars['String']>;
  getPerksFromSearch: AllPerksSearchResult;
  getUsersClaimedPerks: Array<ClaimedPerkDetail>;
  getAllBrands: Array<BasicBrand>;
  getPerkClaimAttemptsForUser: Array<ClaimPerkAttempt>;
  getUserHoldingSymbols: Array<Scalars['String']>;
  getUsersUploads: Array<Scalars['String']>;
  getNumberOfClaimsForPerk: Scalars['Int'];
  getClaimedPerkShare?: Maybe<ClaimedPerkShare>;
  getPerkClaimPending: Scalars['Boolean'];
  getAllReferralBrokerageLimits?: Maybe<Array<ReferralBrokerageLimit>>;
  getReferAFriendCampaign?: Maybe<Array<ReferAFriendCampaign>>;
  getAllFuzzyMatchParameters?: Maybe<Array<FuzzyMatchParameters>>;
  getUserHoldingTransactions: Array<UserHoldingTransactions>;
  getFingerprintValuesWeb?: Maybe<Array<FingerprintValuesWeb>>;
  getFingerprintValuesMobile?: Maybe<Array<FingerprintValuesMobile>>;
  getDocumentDetails: DocumentResponse;
  getAllAiAdminSettings?: Maybe<Array<AiAdminSettings>>;
  getManualUploadsForUser?: Maybe<Array<ManualUploads>>;
  getAllManualUploads?: Maybe<Array<ManualUploads>>;
};


export type QueryGetUsersPendingReferralsArgs = {
  status?: Maybe<Scalars['String']>;
};


export type QueryGetInstitutionLoginsForUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetActiveInstitutionLoginsForUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetInstitutionLoginArgs = {
  institutionLoginId: Scalars['Int'];
};


export type QueryGetLifestyleQuestionsForUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserPerksArgs = {
  tickerSymbol?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetHasActivePerksForBrandArgs = {
  tickerSymbol: Scalars['String'];
};


export type QueryGetHasClaimedPerkForBrandArgs = {
  tickerSymbol: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryGetUserBrandInterestArgs = {
  userId: Scalars['Int'];
  tickerSymbol: Scalars['String'];
};


export type QuerySearchArgs = {
  searchTerm: Scalars['String'];
};


export type QueryGetHasCompanyStoreArgs = {
  tickerSymbol: Scalars['String'];
};


export type QueryGetStoreAutoLoginTokenArgs = {
  tickerSymbolOrSubdomain: Scalars['String'];
};


export type QueryGetHasEnoughPointsArgs = {
  userId: Scalars['Int'];
  points: Scalars['Int'];
};


export type QueryGetCompletedGamesArgs = {
  userId: Scalars['Int'];
};


export type QueryInsightsSearchArgs = {
  searchTerm?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  authors?: Maybe<Array<Maybe<Scalars['String']>>>;
  asc?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  searchToken: Scalars['String'];
  featured?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUserCouponCodeForPerkArgs = {
  userId: Scalars['Int'];
  perkId: Scalars['String'];
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryGetUserArgs = {
  userId: Scalars['Int'];
};


export type QueryBrandSearchArgs = {
  searchTerm: Scalars['String'];
};


export type QueryPaginatedHoldingsArgs = {
  userId?: Maybe<Scalars['Int']>;
  paginationOptions?: Maybe<HoldingPaginationOptions>;
};


export type QueryGetUserDetailedHoldingsForSymbolsArgs = {
  userId: Scalars['Int'];
  symbols: Array<Scalars['String']>;
};


export type QueryGetUserHoldingCountArgs = {
  userId?: Maybe<Scalars['Int']>;
  symbol: Scalars['String'];
};


export type QueryGetUserHoldingsForSymbolsArgs = {
  userId?: Maybe<Scalars['Int']>;
  symbols: Array<Scalars['String']>;
};


export type QueryGetCategoriesArgs = {
  type: CategoryType;
  search?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryGetEntitiesByCategoryArgs = {
  categoryId: Scalars['Int'];
};


export type QueryGetEntityArgs = {
  id: Scalars['Int'];
};


export type QueryGetEntitiesArgs = {
  entityType: EntityType;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetSweepstakesEntryPointsArgs = {
  userId: Scalars['Int'];
  sweepstakesName: Scalars['String'];
};


export type QueryGetRecommendationsForUserArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetNftBucketArgs = {
  bucketId: Scalars['Int'];
};


export type QueryGetNftDropArgs = {
  dropId: Scalars['Int'];
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['Int'];
};


export type QueryGetPaginatedNftForBucketArgs = {
  bucketId: Scalars['Int'];
  page: Scalars['Int'];
  includeBlacklisted?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchNftArgs = {
  name: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryGetUserPromosArgs = {
  userId: Scalars['Int'];
};


export type QueryRecentlyClaimedNftsArgs = {
  top: Scalars['Int'];
};


export type QueryGetUserPerkCertificateUrlArgs = {
  perkId: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryGetAxomoProductInfoArgs = {
  productId: Scalars['String'];
  storeTicker: Scalars['String'];
};


export type QueryGetAxomoProductImageArgs = {
  productId: Scalars['String'];
  text: Scalars['String'];
  chosenBackground: Scalars['String'];
  storeTicker: Scalars['String'];
};


export type QueryGetActiveCartArgs = {
  sessionId: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderId: Scalars['Int'];
};


export type QueryGetReferralPromoCodeArgs = {
  id: Scalars['Int'];
};


export type QueryGetGlobalNotificationArgs = {
  id: Scalars['Int'];
};


export type QueryGetPaginatedPromoCodesArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryGetPaginatedGlobalNotificationsArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryGetGlobalNotificationAcknowledgementsForUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetAlertsForUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetAlertsForUserWithOneConnectionIssueArgs = {
  userId: Scalars['Int'];
};


export type QueryGetReferralPromoCodesForUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetShippingCostArgs = {
  sessionId: Scalars['String'];
};


export type QueryGetNewsByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetNewsForUserArgs = {
  pageSize: Scalars['Int'];
};


export type QueryGetBrandsFromSearchArgs = {
  searchTerm?: Maybe<Scalars['String']>;
  perksAvailableOnly: Scalars['Boolean'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortBy: Scalars['String'];
  sortByDescending: Scalars['Boolean'];
};


export type QueryGetInsightsFromSearchArgs = {
  searchTerm?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortByDescending: Scalars['Boolean'];
};


export type QueryGetCryptoNewsArgs = {
  count: Scalars['Int'];
};


export type QueryGetWiim24HoursArgs = {
  tickers: Array<Scalars['String']>;
};


export type QueryGetPerksFromSearchArgs = {
  searchTerm?: Maybe<Scalars['String']>;
  availableToClaimOnly: Scalars['Boolean'];
  availableToClaimIds: Array<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortByDescending: Scalars['Boolean'];
};


export type QueryGetPerkClaimAttemptsForUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetUsersUploadsArgs = {
  tickerSymbol: Scalars['String'];
};


export type QueryGetNumberOfClaimsForPerkArgs = {
  perkId: Scalars['String'];
};


export type QueryGetClaimedPerkShareArgs = {
  claimedPerkId: Scalars['Int'];
};


export type QueryGetPerkClaimPendingArgs = {
  perkId: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryGetUserHoldingTransactionsArgs = {
  userId: Scalars['Int'];
  tickerSymbol: Scalars['String'];
};


export type QueryGetDocumentDetailsArgs = {
  jobId: Scalars['String'];
};


export type QueryGetManualUploadsForUserArgs = {
  userId: Scalars['Int'];
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  type: AuthenticateResponseType;
  question: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type ReferAFriendCampaign = {
  __typename?: 'ReferAFriendCampaign';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  startDate: Scalars['TimeIsoDate'];
  endDate: Scalars['TimeIsoDate'];
  creationDate: Scalars['TimeIsoDate'];
};

export type ReferAFriendForm = {
  __typename?: 'ReferAFriendForm';
  email: Scalars['String'];
};

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['Int'];
  referralCode: Scalars['String'];
  referredFromUserId: Scalars['Int'];
  referredUserId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  referredDate: Scalars['TimeIsoDate'];
  referralAcceptedDate?: Maybe<Scalars['TimeIsoDate']>;
  email: Scalars['String'];
};

export type ReferralBrokerageLimit = {
  __typename?: 'ReferralBrokerageLimit';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  brokerageRequirement: Scalars['Int'];
  notes: Scalars['String'];
  creationDate: Scalars['TimeIsoDate'];
};

export type ReferralPromoCode = {
  __typename?: 'ReferralPromoCode';
  id: Scalars['Int'];
  name: Scalars['String'];
  referralCode: Scalars['String'];
  creditAmount?: Maybe<Scalars['Float']>;
  productIds: Array<Scalars['String']>;
  securityGroup?: Maybe<Scalars['String']>;
  deactivationDate?: Maybe<Scalars['TimeIsoDate']>;
  createdAt: Scalars['TimeIsoDate'];
  updatedAt: Scalars['TimeIsoDate'];
};

export type ReferralPromoCodeData = {
  name: Scalars['String'];
  creditAmount?: Maybe<Scalars['Float']>;
  productIds: Array<Scalars['String']>;
  securityGroup?: Maybe<Scalars['String']>;
  deactivationDate?: Maybe<Scalars['TimeIsoDate']>;
};

export type ReferralReward = {
  __typename?: 'ReferralReward';
  id: Scalars['Int'];
  referralId: Scalars['Int'];
  userId: Scalars['Int'];
  userType: Scalars['String'];
  couponCodeId: Scalars['Int'];
  claimedDate: Scalars['TimeIsoDate'];
  awardAmount: Scalars['Float'];
};

export type RequestedPerkData = {
  __typename?: 'RequestedPerkData';
  tickerSymbol: Scalars['String'];
  name: Scalars['String'];
  liked: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
};

export enum SearchEntity {
  Brand = 'Brand',
  Article = 'Article',
  Perk = 'Perk',
  Keyword = 'Keyword'
}

export type SearchInsightResult = {
  __typename?: 'SearchInsightResult';
  id: Scalars['Int'];
  title: Scalars['String'];
  slug: Scalars['String'];
  datePublished: Scalars['IsoDate'];
  imageUrl?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type SearchInsights = {
  __typename?: 'SearchInsights';
  totalPages: Scalars['Int'];
  results: Array<SearchInsightResult>;
};

export type SearchNftResult = {
  __typename?: 'SearchNftResult';
  nftId: Scalars['Int'];
  bucketName: Scalars['String'];
  nftBuckets: Array<NftSearchBucketResult>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  iex?: Maybe<Array<IexCompanySearchResult>>;
  insights?: Maybe<Array<InsightSearchResult>>;
  perks?: Maybe<Array<PerkSearchResult>>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockDetail = {
  __typename?: 'StockDetail';
  id: Scalars['String'];
  symbol: Scalars['String'];
  totalValue: Scalars['Float'];
  sharesOwned: Scalars['Float'];
};

export type StockHolding = {
  __typename?: 'StockHolding';
  tickerSymbol: Scalars['String'];
  stockName: Scalars['String'];
  quantity: Scalars['Int'];
  closePrice?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  transactionDate?: Maybe<Scalars['String']>;
};

export type StockInfo = {
  __typename?: 'StockInfo';
  date?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  brokerage?: Maybe<Scalars['String']>;
  holdings?: Maybe<Array<Maybe<StockHolding>>>;
  accountNumber?: Maybe<Scalars['String']>;
  portfolioValue?: Maybe<Scalars['Float']>;
};

export type SubmitAiAdminSettingsForm = {
  __typename?: 'SubmitAiAdminSettingsForm';
  fraudScore: Scalars['Int'];
};

export type SubmitFuzzyMatchParametersForm = {
  __typename?: 'SubmitFuzzyMatchParametersForm';
  nameSimilarity: Scalars['Int'];
  maskSimilarity: Scalars['Int'];
  batchEndTime: Scalars['TimeIsoDate'];
};

export type SubmitManualUpload = {
  __typename?: 'SubmitManualUpload';
  userId: Scalars['Int'];
  jobId: Scalars['String'];
  s3ImageBucket: Scalars['String'];
  s3DocumentBucket: Scalars['String'];
  s3ImageKey: Scalars['String'];
  s3DocumentKey: Scalars['String'];
  status: Scalars['String'];
  creationDate: Scalars['TimeIsoDate'];
  needsApproval?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
};

export type SubmitPhoneVerificationForm = {
  __typename?: 'SubmitPhoneVerificationForm';
  phoneNumber: Scalars['String'];
  verificationNumber: Scalars['String'];
  verified: Scalars['Boolean'];
  creationDate?: Maybe<Scalars['TimeIsoDate']>;
  verifiedDate?: Maybe<Scalars['TimeIsoDate']>;
};

export type SubmitReferralBrokerageLimitForm = {
  __typename?: 'SubmitReferralBrokerageLimitForm';
  brokerageRequirement: Scalars['Int'];
  notes: Scalars['String'];
};

export type SubmitReferralCampaignForm = {
  __typename?: 'SubmitReferralCampaignForm';
  startDate: Scalars['TimeIsoDate'];
  endDate: Scalars['TimeIsoDate'];
};

export type SubmitWhitepaperForm = {
  __typename?: 'SubmitWhitepaperForm';
  name: Scalars['String'];
  email: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  paperType: Scalars['String'];
};

export enum SweepstakesName {
  Elon = 'Elon',
  Hood = 'Hood'
}

export type TangoCatalog = {
  __typename?: 'TangoCatalog';
  id?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  brandKey?: Maybe<Scalars['String']>;
  utid?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
};

export type TextractBlock = {
  __typename?: 'TextractBlock';
  BlockType?: Maybe<Scalars['String']>;
  Confidence?: Maybe<Scalars['Float']>;
  Text?: Maybe<Scalars['String']>;
  Page?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['String']>;
  TextType?: Maybe<Scalars['String']>;
  Geometry?: Maybe<TextractGeometry>;
};

export type TextractGeometry = {
  __typename?: 'TextractGeometry';
  BoundingBox?: Maybe<BoundingBox>;
  Polygon?: Maybe<Array<Maybe<Point>>>;
};

export type TextractResponse = {
  __typename?: 'TextractResponse';
  JobStatus?: Maybe<Scalars['String']>;
  DocumentMetadata?: Maybe<DocumentMetadata>;
  AnalyzeDocumentModelVersion?: Maybe<Scalars['String']>;
  Blocks?: Maybe<Array<Maybe<TextractBlock>>>;
};


export type Trading = {
  __typename?: 'Trading';
  id: Scalars['String'];
  getOAuthUrlForNewLogin: OAuthUrlBundle;
  getOAuthUrlForExistingLogin: OAuthUrlBundle;
  getAccountStatus: AccountStatusType;
};


export type TradingGetOAuthUrlForNewLoginArgs = {
  redirectUrl: Scalars['String'];
  institution?: Maybe<Institution>;
};


export type TradingGetOAuthUrlForExistingLoginArgs = {
  institutionLoginId: Scalars['Int'];
  institutionAccountId?: Maybe<Scalars['Int']>;
  stockSymbol?: Maybe<Scalars['String']>;
  redirectUrl: Scalars['String'];
};


export type TradingGetAccountStatusArgs = {
  institutionLoginId: Scalars['Int'];
};

export type UpdateAllUserInformationInputs = {
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  birthDateRange?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['IsoDate']>;
  ethnicity?: Maybe<Scalars['String']>;
  investibleAssets?: Maybe<Scalars['String']>;
  householdIncome?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  numberOfChildren?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  pets?: Maybe<Array<Scalars['String']>>;
};

export type UpdateFingerprintValuesMobile = {
  __typename?: 'UpdateFingerprintValuesMobile';
  suspectScore: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  emulator: Scalars['Int'];
  deviceTamper: Scalars['Int'];
  clonedApp: Scalars['Int'];
  factoryReset: Scalars['Int'];
  frida: Scalars['Int'];
  jailBroken: Scalars['Int'];
  vpn: Scalars['Int'];
};

export type UpdateFingerprintValuesWeb = {
  __typename?: 'UpdateFingerprintValuesWeb';
  suspectScore: Scalars['Int'];
  botDetection: Scalars['Int'];
  incognito: Scalars['Int'];
  vpn: Scalars['Int'];
  browserTampering: Scalars['Int'];
  virtualMachine: Scalars['Int'];
  privacySetting: Scalars['Int'];
  geolocationSpoofing: Scalars['Int'];
  dataCenter: Scalars['Int'];
};

export type UpdateGiftcardForm = {
  __typename?: 'UpdateGiftcardForm';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  active: Scalars['Boolean'];
  tangoUtid: Scalars['String'];
};

export type UpdateUserAccountInputs = {
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['IsoDate']>;
  birthDateRange?: Maybe<Scalars['String']>;
};

export type UpdateUserDemographicInputs = {
  investibleAssets?: Maybe<Scalars['String']>;
  householdIncome?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  numberOfChildren?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  pets?: Maybe<Array<Scalars['String']>>;
  ethnicity?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  auth0Id: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  interests: Array<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  streetAddress1?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  dateOfBirth?: Maybe<Scalars['IsoDate']>;
  birthDateRange?: Maybe<Scalars['String']>;
  agreedToConnectAcctsTermsOn?: Maybe<Scalars['TimeIsoDate']>;
  hasAgreedToMailingUpdatesOn?: Maybe<Scalars['TimeIsoDate']>;
  investibleAssets?: Maybe<Scalars['String']>;
  householdIncome?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  numberOfChildren?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  pets?: Maybe<Array<Scalars['String']>>;
  institutionLogins: Array<InstitutionLogin>;
  institutionAccounts: Array<InstitutionAccount>;
  holdings: Array<Holding>;
  globalNotificationAcknowledgements: Array<GlobalNotificationAcknowledgement>;
  userMetadata?: Maybe<UserMetadata>;
  userName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  availablePoints?: Maybe<Scalars['Int']>;
  claimedPoints?: Maybe<Scalars['Int']>;
};

export type UserAlert = {
  __typename?: 'UserAlert';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  alertId?: Maybe<Scalars['Int']>;
  acknowledgedAt?: Maybe<Scalars['TimeIsoDate']>;
  createdOn: Scalars['TimeIsoDate'];
  customTitle?: Maybe<Scalars['String']>;
  customDescription?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  alert?: Maybe<Alert>;
};

export type UserBrandInterest = {
  __typename?: 'UserBrandInterest';
  id?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  tickerSymbol: Scalars['String'];
};

export type UserCompanyStore = {
  __typename?: 'UserCompanyStore';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  compnayStoreId: Scalars['Int'];
  createdAt?: Maybe<Scalars['TimeIsoDate']>;
};

export type UserHoldingTransactions = {
  __typename?: 'UserHoldingTransactions';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  tickerSymbol: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  transactionType: Scalars['String'];
  date: Scalars['IsoDate'];
};

export type UserLifestyle = {
  __typename?: 'UserLifestyle';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  questionId: Scalars['String'];
  answer?: Maybe<Scalars['String']>;
  answered: Scalars['Boolean'];
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  signupMethod?: Maybe<Scalars['String']>;
  benzingaListener?: Maybe<Scalars['Boolean']>;
  brandsPageViewed?: Maybe<Scalars['Boolean']>;
  disablePerks?: Maybe<Scalars['Boolean']>;
};

export type UserMetadataInput = {
  benzingaListener?: Maybe<Scalars['Boolean']>;
  brandsPageViewed?: Maybe<Scalars['Boolean']>;
};

export type UserPerk = {
  __typename?: 'UserPerk';
  id: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
  perkId: Scalars['String'];
  tickerSymbol: Scalars['String'];
  eligible: EligibilityStatus;
  claims: Array<ClaimedPerk>;
  title: Scalars['String'];
  numberOfSharesRequired: Scalars['Int'];
  numberOfWeeksHeldRequired: Scalars['Int'];
  startDate?: Maybe<Scalars['TimeIsoDate']>;
  endDate?: Maybe<Scalars['TimeIsoDate']>;
  qualificationType?: Maybe<Scalars['String']>;
  shareDollarValue?: Maybe<Scalars['Float']>;
  totalPortfolioValue?: Maybe<Scalars['Float']>;
  qualifyingBrands?: Maybe<Array<Scalars['String']>>;
  recurringWeekDuration?: Maybe<Scalars['Int']>;
  limitedQuantityNumber?: Maybe<Scalars['Int']>;
};

export type UserPromoCode = {
  __typename?: 'UserPromoCode';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  claimedDate?: Maybe<Scalars['TimeIsoDate']>;
  expirationDate?: Maybe<Scalars['TimeIsoDate']>;
  itemUrl: Scalars['String'];
};

export type UserRecommendation = {
  __typename?: 'UserRecommendation';
  id: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
  brands: Array<BrandRecommendation>;
};


export type UserRecommendationBrandsArgs = {
  recommendationCount?: Maybe<Scalars['Int']>;
};

export type UserReferralCode = {
  __typename?: 'UserReferralCode';
  referralCode: Scalars['String'];
  userId: Scalars['Int'];
};

export type UserReferralPromoCode = {
  __typename?: 'UserReferralPromoCode';
  id: Scalars['Int'];
  name: Scalars['String'];
  creditAmount?: Maybe<Scalars['Float']>;
  productIds: Array<Scalars['String']>;
  redemptionLink?: Maybe<Scalars['String']>;
  nftName?: Maybe<Scalars['String']>;
};

export type UserStockHoldingResponse = {
  __typename?: 'UserStockHoldingResponse';
  id: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  certificateId?: Maybe<Scalars['String']>;
};

export type UserUpload = {
  __typename?: 'UserUpload';
  id: Scalars['Int'];
  fileType: Scalars['String'];
  fileName: Scalars['String'];
  uploadType: Scalars['String'];
  createdAt: Scalars['TimeIsoDate'];
  updatedAt: Scalars['TimeIsoDate'];
  signedUploadUrl?: Maybe<Scalars['String']>;
  metadataAsString?: Maybe<Scalars['String']>;
};

export type UserUploadCreateOrUpdate = {
  id?: Maybe<Scalars['Int']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  uploadType: UserUploadType;
  metadataAsString?: Maybe<Scalars['String']>;
};

export enum UserUploadType {
  ManualStockVerification = 'ManualStockVerification',
  NftBackground = 'NftBackground'
}

export type UserWallet = {
  __typename?: 'UserWallet';
  id: Scalars['Int'];
  walletAddress: Scalars['String'];
};

export type SubBrandSearchResult = {
  __typename?: 'subBrandSearchResult';
  name: Scalars['String'];
};

export type GetReferralsAssociatedWithUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReferralsAssociatedWithUserQuery = (
  { __typename?: 'Query' }
  & { getReferralsAssociatedWithUser: Array<(
    { __typename?: 'AssociatedReferral' }
    & Pick<Types.AssociatedReferral, 'id' | 'referralCode' | 'referredFromUserId' | 'referredUserId' | 'status' | 'referredDate' | 'referralAcceptedDate' | 'email' | 'referrerFirstName' | 'referrerLastName' | 'rewardClaimedByReferredUserDate' | 'rewardClaimedByReferredFromUserDate' | 'couponCode' | 'redemptionUrl'>
  )> }
);


export const GetReferralsAssociatedWithUserDocument = gql`
    query GetReferralsAssociatedWithUser {
  getReferralsAssociatedWithUser {
    id
    referralCode
    referredFromUserId
    referredUserId
    status
    referredDate
    referralAcceptedDate
    email
    referrerFirstName
    referrerLastName
    rewardClaimedByReferredUserDate
    rewardClaimedByReferredFromUserDate
    couponCode
    redemptionUrl
  }
}
    `;
export type GetReferralsAssociatedWithUserQueryResult = ApolloReactCommon.QueryResult<GetReferralsAssociatedWithUserQuery, GetReferralsAssociatedWithUserQueryVariables>;
export namespace GetReferralsAssociatedWithUser {
  export type Variables = GetReferralsAssociatedWithUserQueryVariables;
  export type Query = GetReferralsAssociatedWithUserQuery;
  export type GetReferralsAssociatedWithUser = (NonNullable<GetReferralsAssociatedWithUserQuery['getReferralsAssociatedWithUser'][0]>);
  export const Document = GetReferralsAssociatedWithUserDocument;
}


              export namespace GetReferralsAssociatedWithUser {
                export const _variables : GetReferralsAssociatedWithUserQueryVariables = null as any;
                export const _result : Query = null as any;
              }